<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      align="left"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :sopFireFightingDocumentClassCd="tab.sopFireFightingDocumentClassCd"
          :gubun="tab.gubun"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'selfDefenseFireBrigade',
  data() {
    return {
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '화재발생시 행동요령', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
          sopFireFightingDocumentClassCd: 'FFDC000001' },
        { name: 'tab2', label: '화재진압', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
          sopFireFightingDocumentClassCd: 'FFDC000002' },
        { name: 'tab3', label: '인명대피', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
          sopFireFightingDocumentClassCd: 'FFDC000003' },
        { name: 'tab4', label: '화재 시뮬레이션', component: () => import(`${'./fireFightingDocumentTab.vue'}`),
          gubun: 'simulation', },
        { name: 'tab5', label: '자위소방대', component: () => import(`${'./fireFightingDocumentTab.vue'}`),
          gubun: 'self', },
      ],
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>